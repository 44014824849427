
<template>
  <div class="blog-item">
    <v-breadcrumbs class="ma-0 pa-0 breadcrumb">
      <v-breadcrumbs-item class="size-18">
        <router-link to="/blog" class="router-link">
          <span class="span-1"> {{ $t("blog_page.title") }} </span>
        </router-link>
      </v-breadcrumbs-item>
      <v-icon>mdi-chevron-right</v-icon>
      <v-breadcrumbs-item :disabled="true" class="size-18">
        <span class="span-2 font-family-raleway-medium">
          {{ $t(blogs[$route.params.slug - 1].title) }}
        </span>
      </v-breadcrumbs-item>
    </v-breadcrumbs>

    <v-btn class="my-5 back-button" @click="goBack">
      <v-icon>mdi-arrow-left</v-icon>
      <span>{{ $t("back_button") }}</span>
    </v-btn>
    <v-row>
      <v-col cols="12">
        <v-card class="blog-card border-16 text-left">
          <v-img
            class="image border-16"
            height="500"
            :src="blogs[$route.params.slug - 1].img"
          ></v-img>
          <v-card-title class="title py-4 px-0">{{
            $t(blogs[$route.params.slug - 1].title)
          }}</v-card-title>
          <v-card-text class="text pt-2 px-0">
            <p class="mb-2 font-family-raleway-medium">
              {{ $t(blogs[$route.params.slug - 1].description) }}
            </p>
            <ul
              class="mr-10 my-5 font-family-raleway-medium"
              v-for="(item, index) of blogs[$route.params.slug - 1].list"
              :key="index"
            >
              <div v-if="item.list">
                <h3>{{ $t(item.title) }}</h3>
                <p>{{ $t(item.description) }}</p>
                <ul>
                  <li
                    v-for="i in item.list"
                    :key="i"
                    class="ml-1"
                    style="color: black; font-size: 15px"
                  >
                    {{ $t(i) }}
                  </li>
                </ul>
                <p class="mt-5">{{ $t(item.text) }}</p>
              </div>
              <li v-else class="ml-10">
                <p class="mb-2">{{ $t(item) }}</p>
              </li>
            </ul>
            <p class="mb-2 font-family-raleway-medium">
              {{ $t(blogs[$route.params.slug - 1].text) }}
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <Footer bg-color="#b9b9b9"/>
  </div>
</template>

<script>
import clock from "@/assets/img/clock.svg";
import forward from "@/assets/img/forward.svg";
import { currentDate } from "@/utils";

export default {
  name: "BlogDetails",
  components: {
    Footer: () => import("@/components/Footer")
  },
  methods: {
    goBack() {
      this.$router.back();
    },
  },
  data: () => ({
    blogs: [
      {
        id: 1,
        date: "02 Sep, 2023",
        title: "blog_page.blog1_title",
        img: require("/src/assets/img/how-market.png"),
        description: "blog_page.blog1_description",
        list: [
          "blog_page.blog1_list1",
          "blog_page.blog1_list2",
          "blog_page.blog1_list3",
          "blog_page.blog1_list4",
          "blog_page.blog1_list5",
        ],
        text: "blog_page.blog1_text",
        views: 203,
        messages: 22,
        shareCount: 7,
      },
      {
        id: 2,
        date: "02 Sep, 2023",
        title: "blog_page.blog2_title",
        img: require("/src/assets/img/how-get-customers.png"),
        description: "blog_page.blog2_description",
        list: [
          "blog_page.blog2_list1",
          "blog_page.blog2_list2",
          "blog_page.blog2_list3",
          "blog_page.blog2_list4",
          "blog_page.blog2_list5",
          "blog_page.blog2_list6",
        ],
        text: "blog_page.blog2_text",
        views: 203,
        messages: 22,
        shareCount: 7,
      },
      {
        id: 3,
        date: "02 Sep, 2023",
        title: "blog_page.blog3_title",
        img: require("/src/assets/img/maximazing-your-efforts.png"),
        description: "blog_page.blog3_description",
        list: [
          "blog_page.blog3_list1",
          "blog_page.blog3_list2",
          "blog_page.blog3_list3",
          "blog_page.blog3_list4",
        ],
        text: "blog_page.blog3_text",
        views: 203,
        messages: 22,
        shareCount: 7,
      },
      {
        id: 4,
        date: "02 Sep, 2023",
        title: "blog_page.blog4_title",
        img: require("/src/assets/img/maximazing-your-potensial.png"),
        description: "blog_page.blog4_description",
        list: [
          "blog_page.blog4_list1",
          "blog_page.blog4_list2",
          "blog_page.blog4_list3",
          "blog_page.blog4_list4",
        ],
        text: "blog_page.blog4_text",
        views: 203,
        messages: 22,
        shareCount: 7,
      },
      {
        id: 5,
        date: "02 Sep, 2023",
        title: "blog_page.blog5_title",
        img: require("/src/assets/img/how-promote.png"),
        description: "blog_page.blog5_description",
        list: [
          "blog_page.blog5_list1",
          "blog_page.blog5_list2",
          "blog_page.blog5_list3",
          "blog_page.blog5_list4",
        ],
        text: "blog_page.blog5_text",
        views: 203,
        messages: 22,
        shareCount: 7,
      },
      {
        id: 6,
        date: "02 Sep, 2021",
        title: "blog_page.blog6_title",
        img: require("/src/assets/img/how-aquire.png"),
        description: "blog_page.blog6_description",
        list: [
          {
            title: "blog_page.blog6_list1_item1_title",
            description: "blog_page.blog6_list1_item1_description",
            list: [
              "blog_page.blog6_list1_item1_list1",
              "blog_page.blog6_list1_item1_list2",
              "blog_page.blog6_list1_item1_list3",
            ],
            text: "blog_page.blog6_list1_item1_text",
          },
          {
            title: "blog_page.blog6_list1_item2_title",
            description: "blog_page.blog6_list1_item2_description",
            list: [
              "blog_page.blog6_list1_item2_list1",
              "blog_page.blog6_list1_item2_list2",
              "blog_page.blog6_list1_item2_list3",
              "blog_page.blog6_list1_item2_list4",
              "blog_page.blog6_list1_item2_list5",
              "blog_page.blog6_list1_item2_list6",
              "blog_page.blog6_list1_item2_list7",
            ],
            text: "blog_page.blog6_list1_item2_text",
          },
          {
            title: "blog_page.blog6_list1_item3_title",
            description: "blog_page.blog6_list1_item3_description",
            list: [
              "blog_page.blog6_list1_item3_list1",
              "blog_page.blog6_list1_item3_list2",
              "blog_page.blog6_list1_item3_list3",
            ],
            text: "blog_page.blog6_list1_item3_text",
          },
        ],
        text: "blog_page.blog6_text",
        views: 203,
        messages: 22,
        shareCount: 7,
      },
      {
        id: 7,
        date: "02 Sep, 2023",
        title: "blog_page.blog7_title",
        img: require("/src/assets/img/future-digital.png"),
        description: "blog_page.blog7_description",
        list: [
          "blog_page.blog7_list1",
          "blog_page.blog7_list2",
          "blog_page.blog7_list3",
          "blog_page.blog7_list4",
          "blog_page.blog7_list5",
          "blog_page.blog7_list6",
          "blog_page.blog7_list7",
          "blog_page.blog7_list8",
        ],
        text: "",
        views: 203,
        messages: 22,
        shareCount: 7,
      },
      {
        id: 8,
        date: "02 Sep, 2023",
        title: "blog_page.blog8_title",
        img: require("/src/assets/img/maximizing-ad.png"),
        description: "blog_page.blog8_description",
        list: [
          {
            title: "",
            description: "blog_page.blog8_list1_item1_description",
            list: [
              "blog_page.blog8_list1_item1_list1",
              "blog_page.blog8_list1_item1_list2",
            ],
            text: "",
          },
          {
            title: "",
            description: "blog_page.blog8_list2_item2_description",
            list: [
              "blog_page.blog8_list2_item2_list1",
              "blog_page.blog8_list2_item2_list2",
            ],
            text: "",
          },
          {
            title: "",
            description: "blog_page.blog8_list3_item3_description",
            list: [
              "blog_page.blog8_list3_item3_list1",
              "blog_page.blog8_list3_item3_list2",
            ],
            text: "",
          },
          {
            title: "",
            description: "blog_page.blog8_list4_item4_description",
            list: [
              "blog_page.blog8_list4_item4_list1",
              "blog_page.blog8_list4_item4_list2",
              "blog_page.blog8_list4_item4_list3",
              "blog_page.blog8_list4_item4_list4",
            ],
            text: "",
          },
          {
            title: "",
            description: "blog_page.blog8_list5_item5_description",
            list: [
              "blog_page.blog8_list5_item5_list1",
            ],
            text: "",
          },
        ],
        text: "",
        views: 203,
        messages: 22,
        shareCount: 7,
      },
    ],
    forward,
    clock,
  }),
  created() {
    const { slug } = this.$route.params;
    this.blog = this.blogs?.find((item) => item.id === slug);
  },
  computed: {
    getCurrentYear() {
      return currentDate;
    },
  },
};
</script>

<style scoped lang="scss">
.blog-item {
  margin-top: 30px;
  margin-bottom: 20px;

  @media (max-width: 960px) {
    margin-top: 0;
    margin-bottom: 5px;
  }

  .back-button {
    background-color: transparent !important;
    text-transform: capitalize;
    padding: 0 !important;
    box-shadow: none;

    @media (max-width: 960px) {
      color: #ffffff !important;
    }

    &:hover span {
      text-decoration: underline;
    }

    span {
      font-size: 18px;
    }
  }

  .size-18 {
    font-size: 18px !important;
  }

  .breadcrumb {
    display: block !important;
    @media (max-width: 960px) {
      display: none !important;
    }

    .router-link {
      text-decoration: none !important;
    }

    .span-1 {
      color: #828282 !important;
    }

    .span-2 {
      color: #11263c !important;
    }
  }

  .blog-card {
    height: auto !important;
    .image {
      @media (max-width: 960px) {
        height: 250px !important;
      }
    }

    box-shadow: none !important;
    @media (max-width: 960px) {
      box-shadow: 0px 4px 24px rgba(34, 41, 51, 0.1) !important;
    }

    .date-val,
    .view-val,
    .share-val {
      font-family: sans-serif !important;
      font-size: 16px !important;
    }

    .title {
      font-size: 32px !important;
      font-family: Raleway sans-serif !important;
      font-weight: bold !important;
      @media (max-width: 960px) {
        padding: 5px 15px !important;
        font-size: 24px !important;
      }
    }

    .text {
      font-family: "Raleway", sans-serif !important;
      @media (max-width: 960px) {
        padding: 5px 15px !important;
      }

      p {
        color: #11263c !important;
        font-size: 18px;
      }
    }
  }

  .share-btn {
    background: rgba(0, 0, 0, 0.05);

    &:hover {
      background-color: #0057ff;
      color: white !important;
    }
  }
}
</style>
